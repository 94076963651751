"use client";

import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";
function Providers({
  children
}: React.PropsWithChildren) {
  const [client] = React.useState(new QueryClient());
  return <QueryClientProvider client={client} data-sentry-element="QueryClientProvider" data-sentry-component="Providers" data-sentry-source-file="Provider.tsx">
      <ReactQueryStreamedHydration data-sentry-element="ReactQueryStreamedHydration" data-sentry-source-file="Provider.tsx">{children}</ReactQueryStreamedHydration>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" data-sentry-element="ReactQueryDevtools" data-sentry-source-file="Provider.tsx" />
    </QueryClientProvider>;
}
export default Providers;